import React from "react"
import {
  GlobalContainer,
  Button,
  GlobalSection,
} from "../../components/GlobalStyle"
import styled from "styled-components"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import headerImage from "../../images/slider/slide-2.jpg"
import Map from "../../components/Map"

import officeImage from "../../images/icons/skyscrapper.svg"
import messageImage from "../../images/icons/email.svg"
import notificationImage from "../../images/icons/message.svg"
import SectionContact from "../../components/sectionContact"
import Helmet from "react-helmet"
import { useTranslation} from 'gatsby-plugin-react-i18next';

const StyledBody = styled.div`
  background-color: #f4f4f4;
`
const StyledOfferContainer = styled(GlobalContainer)`
  max-width: 1040px;
  margin-bottom: 200px;
  p {
    margin: 20px 0;
  }
`
const StyledFAQContainer = styled(GlobalContainer)`
  margin-top: -100px;
  max-width: 1040px;
  margin-bottom: 200px;
  a {
    font-weight: bold;
    color: var(--main);
  }
  p {
    margin: 20px 0;
  }
  ul {
    li {
      display: flex;
      &:before {
        content: "";
        display: block;
        height: 4px;
        width: 16px;
        background-color: var(--main);
        margin-right: 10px;
        position: relative;
        top: 15px;
      }
    }
    div {
      flex: 1;
      p {
        margin-top: 0;
      }
    }
  }
`
const StyledPageHeaderDetails = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(12, 0, 46, 0.04);
  position: relative;
  transform: translateY(-50%);
  @media (max-width: 800px) {
    flex-direction: column;
    transform: translateY(-50px);
  }
  & > div {
    flex: 1;
    text-align: center;
    padding: 30px 10px;
    font-size: 1.4em;
    line-height: 1.1em;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 20px);
      right: 0;
      display: block;
      height: 40px;
      width: 1px;
      background-color: var(--main);
      @media (max-width: 800px) {
        display: none;
      }
    }
    &:last-of-type:after {
      display: none;
    }
    span {
      text-transform: uppercase;
      font-weight: bold;
      opacity: 0.5;
      display: block;
      font-size: 0.6em;
    }
  }
`

const StyledCenteredBox = styled.div`
  text-align: center;
  margin: 50px 0;
  div {
    margin-bottom: 20px;
  }
`
const StyledOfferFlex = styled.div`
  display: flex;
  margin: 40px 0;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  & > div {
    flex: 1;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    strong {
      color: var(--main);
    }
    p {
      opacity: 0.6;
      line-height: 1.4em;
      @media (max-width: 800px) {
        font-size: 15px;
      }
    }
    span {
      display: block;
      height: 60px;
      width: 60px;
      margin: 20px auto;
      background-size: contain;
      background-repeat: no-repeat;
      &.office-company {
        background-image: url(${officeImage});
      }
      &.office-message {
        background-image: url(${messageImage});
      }
      &.office-notification {
        background-image: url(${notificationImage});
      }
    }
  }
  div:nth-child(2) {
    margin: 0 20px;
    @media (max-width: 720px) {
      margin: 20px 0;
    }
  }
`
const StyledOfferNet = styled.div`
  display: inline-block;
  font-size: 0.9em;
  margin-left: 2px;
  opacity: 0.65;
`

const VirtualOfficePage = () => {
  const { t } = useTranslation()
  return (
    <StyledBody>
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
          "@type": "Question",
          "name": "Gdzie znajdę wirtualne biuro w trójmieście?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "Leśnahub to wirtualne biuro w Sopocie oferujące dodatkowe przestrzenie coworkingowe dla przedsiębiorców, freelancerów i innych właścicieli firm. Przestrzenie coworkingowe są w pełni umeblowane i wyposażone we wszystkie udogodnienia potrzebne do prowadzenia biznesu. Członkostwo jest elastyczne i może być dostosowane do Twoich potrzeb."
        }
        },{
          "@type": "Question",
          "name": "Ile kosztuje wynajem wirtualnego biura?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "Ceny zaczynają się już od 100 PLN/miesiąc."
        }
        },{
          "@type": "Question",
          "name": "Czym jest wirtualne biuro?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "Wirtualne biuro, to adres użyczony Twojej firmie. Wirtualne biuro to adres biznesowy, który w rzeczywistości nie jest przestrzenią fizyczną. Zwykle jest to tylko adres pocztowy i numer telefonu. Firma ma możliwość wynajęcia wirtualnej powierzchni biurowej i wykorzystania jej jako własnego biura, jeśli zechce. Biura wirtualne to świetny sposób na rozpoczęcie działalności, a także korzyści dla wielu przedsiębiorców. Zaoszczędzamy na powierzchni, dodatkowo możemy zyskać prestiżowy adres skrzynki"
        }
        }]
        }
        `}
        </script>
      </Helmet>
      <SEO
        title="Wirtualne biuro"
        description="Nasza przestrzeń oferuje wynajem wirtualnego biura dla Twojej działalności"
      />
      <PageHeader image={headerImage}>
        <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">
				{t("virtualOffice.title1")} {t("virtualOffice.title2")}
        </h1>
      </PageHeader>
      <StyledOfferContainer>
        <StyledPageHeaderDetails
          data-sal="fade"
          data-sal-delay="500"
          data-sal-duration="400"
        >
          <div data-sal="slide-up" data-sal-delay="600" data-sal-duration="400">
            <span>{t("virtualOffice.price")}</span>
						{t("common.from")} 100 zł / {t("virtualOffice.month")}
          </div>
          <div data-sal="slide-up" data-sal-delay="700" data-sal-duration="400">
            <span>{t("offerOfficeRent.location")}</span>
            Sopot
          </div>
          <div data-sal="slide-up" data-sal-delay="750" data-sal-duration="400">
            <span>{t("virtualOffice.notifications")}</span>
            SMS / e-mail
          </div>
        </StyledPageHeaderDetails>
        <section>
          <div>
            <p>
						{t("virtualOffice.desc1")}
              <br />
              <br />
							{t("virtualOffice.desc2")}
              <br />
              <br />
							{t("virtualOffice.desc3")}
            </p>

            <section>
              <StyledOfferFlex>
                <div>
                  <span className="office-company"></span>
                  <h3>{t("virtualOffice.offerTtile1")}</h3>
                  <p>
									{t("virtualOffice.offerText1")}
                  </p>
                </div>
                <div>
                  <span className="office-message"></span>
                  <h3>{t("virtualOffice.offerTtile2")}</h3>
                  <p>	{t("virtualOffice.offerText2")}</p>
                </div>
                <div>
                  <span className="office-notification"></span>
                  <h3>{t("virtualOffice.offerTtile3")}</h3>
                  <p>
									{t("virtualOffice.offerText3")}
                  </p>
                </div>
              </StyledOfferFlex>
            </section>

            <div className="office-flex">
              <div className="office-flex__text"></div>
            </div>
            <section>
              <div>	{t("virtualOffice.packagesDesc")}</div>
              <StyledOfferFlex>
                <div>
                  <div>
                    140 zł
                    <StyledOfferNet>netto</StyledOfferNet>
                  </div>
                  <strong>{t("virtualOffice.package1")}</strong>
                  <div>( 140 zł/{t("virtualOffice.month")} )</div>
                </div>
                <div>
                  <div>
                    720 zł
                    <StyledOfferNet>netto</StyledOfferNet>
                  </div>
                  <strong>{t("virtualOffice.package2")}</strong>
                  <div>( 120 zł/{t("virtualOffice.month")} )</div>
                </div>
                <div>
                  <div>
                    1200 zł
                    <StyledOfferNet>netto</StyledOfferNet>
                  </div>
                  <strong>{t("virtualOffice.package3")}</strong>
                  <div>( 100 zł/{t("virtualOffice.month")} )</div>
                </div>
              </StyledOfferFlex>
            </section>

            <StyledCenteredBox>
              <div>
							{t("virtualOffice.form")}
              </div>
              <Button to="/rezerwacja">{t("common.reservation")}</Button>
            </StyledCenteredBox>
          </div>
        </section>
      </StyledOfferContainer>

      <GlobalSection bg="#F4F4F4">
        <StyledFAQContainer>
          <h2>{t("virtualOffice.articleTitle1")}</h2>
          <p>
          {t("virtualOffice.articleText1")}
          </p>
          <p>
					{t("virtualOffice.articleText2")}
          </p>
          <p>
					{t("virtualOffice.articleText3")}
          </p>
          <p>
					{t("virtualOffice.articleText4")}
          </p>
          <p>
					{t("virtualOffice.articleText5")}
          </p>

          <h2>{t("virtualOffice.articleTitle2")}</h2>
          <p>
					{t("virtualOffice.articleText6")}
          </p>

          <h2>
          {t("virtualOffice.articleTitle3")}
          </h2>
          <p>
					{t("virtualOffice.articleText7")}
          </p>
          <p>
					{t("virtualOffice.articleText8")}
          </p>

          <h2>{t("virtualOffice.articleTitle4")}</h2>
          <p>
          {t("virtualOffice.articleText9")}
          </p>
        </StyledFAQContainer>
      </GlobalSection>

      <Map />
      <SectionContact />
    </StyledBody>
  )
}

export default VirtualOfficePage;
